import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserService } from '../../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // PSE - beginning to be removed
      console.log ("Bug to be fixed ABSOLUTELLY");
      return true;
      // PSE - end to be removed
      return this.userService.authCompleted$.pipe(map(user => !!user), catchError((err) => {
      return this.router.navigate(['/user/login']);
    }));
  }

  constructor(private userService: UserService, private router: Router) {
  }
}
